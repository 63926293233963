import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { ProductItem } from '../__generated__/graphql';

type CartState = {
  items: Array<ProductItem & { count: number }>,
}
const initialState: CartState = {
  items: [],
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addProduct: (state, action: PayloadAction<ProductItem>) => {
      state.items.push({
        ...action.payload,
        count: 1,
      });
    },
    deleteProduct: (state, action: PayloadAction<string>) => {
      state.items = state.items.filter((p) => p.id !== action.payload);
    },
    changeCount: (state, action: PayloadAction<{ product_id: string, count: number }>) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { product_id, count } = action.payload;
      const o = state.items.find((p) => p.id === product_id);
      if (o) {
        if (action.payload.count === 0) {
          state.items = state.items.filter((p) => p.id !== product_id);
        } else {
          state.items = state.items.map((p) => p.id === product_id ? { ...p, count } : p);
        }
      }
    },
    clearCart: (state) => {
      state.items = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { addProduct, deleteProduct, changeCount, clearCart } = cartSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCartItems = (state: RootState) => state.cart.items;

export const selectCartItemCount = (product_id: string) => (state: RootState) => {
  const product = state.cart.items.find((p) => p.id === product_id);
  return product?.count || 0;
};


export default cartSlice.reducer;
