import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { ClientPersonal } from '../__generated__/graphql';
import { jwtDecode } from 'jwt-decode';

// Define App reducer

interface AppState {
  token?: string,
  personal?: ClientPersonal
};

const initialState: AppState = {
  token: undefined,
  personal: undefined
};

export const loginSlice = createSlice({
  name: 'login',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | undefined>) => {
      state.token = action.payload;
      state.personal = action.payload 
        // @ts-ignore
        ? jwtDecode(action.payload as string).personal
        : undefined;
    },
    setPersonal: (state, action: PayloadAction<ClientPersonal | undefined>) => {
      state.personal = action.payload;
    }
  },
});

export const { setToken, setPersonal } = loginSlice.actions;


export const getToken = (state: RootState) => state.login.token;
export const getPersonal = (state: RootState) => state.login.personal;

export default loginSlice.reducer;