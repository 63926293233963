import type { AppProps } from 'next/app';
import { ApolloProvider } from "@apollo/client";
import { Provider } from 'react-redux';
import { client } from "../api/public/graphql";
import store from '../store/store';
import '../styles/globals.scss';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Component {...pageProps} />
      </Provider>
    </ApolloProvider>
  );
}

export default MyApp;