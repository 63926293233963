import { City } from '../__generated__/graphql';
import { useDispatch } from '../store/hooks';
import { setToken } from '../store/loginSlice';
import { setCity } from '../store/mainSlice';

export const STORAGE_KEY = 'ensys_token';

export const useAuth = () => {
  const dispatch = useDispatch();

  const initUser = () => {
    const token = localStorage.getItem(STORAGE_KEY);
    if (token) {
      dispatch(setToken(token));
    }
  };

  const loginUser = ({ token, city }: { token: string, city: City }) => {
    localStorage.setItem(STORAGE_KEY, token);
    dispatch(setToken(token));
    dispatch(setCity(city));
  };

  const logoutUser = () => {
    sessionStorage.removeItem('profile');
    localStorage.removeItem(STORAGE_KEY);
    dispatch(setToken(undefined));
  };

  return { initUser, loginUser, logoutUser };
};
