import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { City } from '../__generated__/graphql';

type MainState = {
  city: City | null,
}
const initialState: MainState = {
  city: null,
};

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setCity: (state, action: PayloadAction<City>) => {
      state.city = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCity } = mainSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCity = (state: RootState) => state.main.city;

export default mainSlice.reducer;
